<template>
    <div class="row align-items-center py-3 border-bottom small">
        <div class="col-6">
            <div class="row align-items-center">
                <div class="col-1 text-center d-flex align-items-center">
                    <input v-model="article.selected" :id="id" type="checkbox" class="d-none">
                    <label :for="id" :class="{'active': article.selected}" class="ratio ratio-1x1 checkbox rounded no-highlight small">
                        <div v-if="article.selected" class="d-flex align-items-center justify-content-center p-1">
                            <i class="fas fa-check fa-sm"></i>
                        </div>
                    </label>
                </div>
                <div class="col line-2 text-capitalize">
                    <div class="text-darkblue fw-bold">{{$t("body.title", article.title) ? $t("body.title", article.title) : $t("body.noTitle") }}</div>
                    <div class="text-muted small">{{$t("body.titleInverse", article.title) ? $t("body.titleInverse", article.title) : $t("body.noInverseTitle") }}</div>
                </div>
                <div class="col-2">{{formatAvailableLanguage(article)}}</div>
            </div>
        </div>
        <div class="col-1">{{article.metadata.viewCount}}</div>
        <div class="col">{{$t("body.title", article.categoryTitle)}}</div>
        <div class="col">{{_formatDateRelation(article.postDate.seconds)}}</div>
        <div class="col">{{article.authorFullname}}</div>
        <div class="col-1 d-flex justify-content-center text-muted">
            <span @click="modify(article.id)">
                <i class="btn-icon fas fa-edit fa-lg"></i>
            </span>
            <!-- <i class="btn-icon fas fa-ellipsis-h fa-lg ms-3"></i> -->
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "body": {
            "title": "{en}",
            "titleInverse": "{th}",
            "noTitle": "No English Title",
            "noInverseTitle": "(No Thai Title)",
        }
    },
    "th": {
        "body": {
            "title": "{th}",
            "titleInverse": "{en}",
             "noTitle": "ไม่มีหัวข้อภาษาไทย",
             "noInverseTitle": "(ไม่มีหัวข้อภาษาอังกฤษ)",
        }
    }
}
</i18n>
<style scoped>
.btn-icon {
    transition: .25s;
}
.btn-icon:hover {
    cursor: pointer;
    color: #444;
}
.checkbox {
    border: 1px solid lightgrey;
    width: 1.625em;
    height: 1.625em;
}
.checkbox.active {
    border-color: var(--navy);
    background: var(--navy);
    color: white;
}
</style>
<script>
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        article: {
            type: Object,
            required: true
        }
    },
    emits: [
        'select',
        'modify'
    ],
    watch: {
        'article.selected': {
            handler() {
                this.$emit('select', this.article.id)
            }
        }
    },
    methods: {
        formatAvailableLanguage(article) {
            const notNull = (value) => value != ""
            const hasEnglishTitle = notNull(article.title.en)
            const hasThaiTitle = notNull(article.title.th)
            const hasEnglishBody = notNull(article.body.en)
            const hasThaiBody = notNull(article.body.th)

            const hasEnglish = hasEnglishTitle && hasEnglishBody
            const hasThai = hasThaiTitle && hasThaiBody

            if (hasEnglish && hasThai) return "EN/TH"
            if (hasEnglish) return "EN"
            if (hasThai) return "TH"
        },
        modify(articleId) {
            this.$emit('modify', articleId)
        }
    }
}
</script>