<template>
    <div>
        <div class="card h-100 border-0 shadow-lg">
            <div class="card-body">
                <div class="row text-muted pt-1 pb-3 border-bottom small">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-1 text-center d-flex align-items-center small">
                                <input v-model="selectedAll" id="selectedAll" type="checkbox" class="d-none">
                                <label for="selectedAll" :class="{'active': selectedAll}" class="ratio ratio-1x1 checkbox rounded no-highlight">
                                    <div v-if="selectedAll" class="d-flex align-items-center justify-content-center p-1">
                                        <i class="fas fa-check fa-sm"></i>
                                    </div>
                                </label>
                            </div>
                            <div class="col">{{$t("header.title")}}</div>
                            <div class="col-2">{{$t("header.language")}}</div>
                        </div>
                    </div>
                    <div class="col-1">{{$t("header.viewCount")}}</div>
                    <div class="col">{{$t("header.category")}}</div>
                    <div class="col">{{$t("header.postDate")}}</div>
                    <div class="col">{{$t("header.author")}}</div>
                    <div class="col-1 text-center">{{$t("header.menu")}}</div>
                </div>
                <div v-if="articles.length">
                    <ArticleCard @select="addSelected" @modify="openEditor" :id="index" :article="article" v-for="(article, index) in lastestNews" :key="index" />
                </div>
                <div v-else class="row py-3 small border-bottom">
                    <div class="text-muted text-center">No news article found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "header": {
            "title": "Title",
            "language": "Lang.",
            "viewCount": "Views",
            "category": "Category",
            "postDate": "Date",
            "author": "Author",
            "menu": "Menus"
        }
    },
    "th": {
        "header": {
            "title": "หัวข้อ",
            "language": "ภาษา",
            "viewCount": "เข้าชม",
            "category": "ประเภท",
            "postDate": "วันที่",
            "author": "ผู้โพสต์",
            "menu": "เมนู"
        }
    }
}
</i18n>
<style scoped>
.checkbox {
    border: 1px solid lightgrey;
    width: 1.625em;
    height: 1.625em;
}
.checkbox.active {
    border-color: var(--navy);
    background: var(--navy);
    color: white;
}
</style>
<script>
import ArticleCard from './ArticleCard.vue'

import { getFullname } from '@/helpers/user.js'
import * as newsDb from '@/helpers/news.js'
import * as categoryDb from '@/helpers/newsCategory.js'

export default {
    components: {
        ArticleCard
    },
    emits: [
        'edit',
        'submit'
    ],
    data() {
        return {
            selectedAll: false,
            selectedArticles: [],
            articles: []
        }
    },
    computed: {
        lastestNews() {
            if (!this.articles) return this.articles
            const list = this.articles
            const sortLastest = (a, b) => (a.postDate > b.postDate) ? -1 : 1
            return list.sort(sortLastest)
        }
    },
    watch: {
        // On change of all-selection then change all selected value in each article
        selectedAll(isSelected) {
            this.articles.forEach(article => article.selected = isSelected)
        },
    },
    methods: {
        async loadArticles() {
            let articles = await newsDb.getNewsArticles()
            for (let article of articles) {
                article.categoryTitle = (await categoryDb.getCategory(article.categoryId)).title
                article.authorFullname = await getFullname(article.authorId)
                article.selected = false
            }
            this.articles = articles
        },
        openEditor(articleId) {
            // Clear all selected articles before opening form
            this.clearSelected()
            this.$emit('edit', articleId)
        },
        addSelected(articleId) {
            let selected = this.selectedArticles
            const index = selected.indexOf(articleId)
            if (index !== -1) selected.splice(index, 1)
            else selected.push(articleId)
        },
        clearSelected() {
            this.selectedAll = false
            this.articles.forEach(article => article.selected = false)
        },
        async deleteSelected() {
            try {
                const result = await newsDb.deleteMultiArticles(this.selectedArticles)
                this.$emit('submit', {type: 'success', message: result})
            } catch(error) {
                this.$emit('submit', {type: 'error', message: error})
            }
            this.clearSelected()
            this.loadArticles()
        }
    },
    async created() {
        await this.loadArticles()
    }
}
</script>