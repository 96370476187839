<template>
    <div class="d-flex flex-column h-100">
        <div class="d-flex mb-3">
            <div class="d-flex flex-grow-1 me-3">
                <button @click="deleteAll()" class="custom-btn bg-white h-100 rounded shadow-sm px-3 py-2 text-danger">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
            <div>
                <button @click="openEditor()" class="small h-100 custom-btn btn-blue rounded-1 px-3 py-2">
                    <i class="fas fa-file-alt"></i>
                    <span class="ms-3">{{$t("createButton")}}</span>
                </button>
            </div>
        </div>
        <ArticleList ref="articleList" @edit="openEditor" class="flex-grow-1" />
    </div>
</template>
<i18n>
{
    "en": {
        "createButton": "Create new article"
    },
    "th": {
        "createButton": "สร้างบทความใหม่"
    }
}
</i18n>
<script>
import ArticleList from './ArticleList.vue'

export default {
    components: {
        ArticleList
    },
    methods: {
        openEditor(articleId) {
            if (articleId !== null) this.$router.push({name: 'NewsArticleCreator'})
            this.$router.push({name: 'NewsArticleCreator', query: {id: articleId}})
        },
        deleteAll() {
            this.$refs.articleList.deleteSelected()
        }
    }
}
</script>